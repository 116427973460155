import { Link } from '@afishauz/core/ui/link';
import { Afishauz } from '@afishauz/icons';
import { Button } from '@afishauz/ui-kit/button';
import { Dialog, DialogTitle } from '@afishauz/ui-kit/dialog';
import { DialogContent } from '@afishauz/ui-kit/dialog';
import { faApple } from '@fortawesome/free-brands-svg-icons/faApple';
import { faGoogle } from '@fortawesome/free-brands-svg-icons/faGoogle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslations } from 'next-intl';

export function AuthModal({
  show = false,
  onClose = () => {},
}: {
  show?: boolean;
  onClose?: () => void;
}) {
  const t = useTranslations('common');

  return (
    <Dialog
      open={show}
      onOpenChange={open => {
        if (!open) {
          onClose();
        }
      }}
    >
      <DialogContent>
        <section className='flex flex-col items-center gap-y-8'>
          <DialogTitle>{t('layouts.auth')}</DialogTitle>
          <div className='flex flex-col items-center gap-y-4'>
            <Button
              component={Link}
              nativeLink
              href='/connect/google'
              className='w-72 flex items-center'
            >
              <span className='relative w-full flex justify-center items-center'>
                <span className='absolute left-0 top-0 flex justify-center items-center h-full text-gray-500 text-xl'>
                  <FontAwesomeIcon icon={faGoogle} />
                </span>
                Google
              </span>
            </Button>
            <Button
              component={Link}
              nativeLink
              href='/connect/apple'
              className='w-72 flex items-center'
            >
              <span className='relative w-full flex justify-center items-center'>
                <span className='absolute left-0 top-0 flex justify-center items-center h-full text-gray-500 text-xl'>
                  <FontAwesomeIcon icon={faApple} />
                </span>
                Apple
              </span>
            </Button>
          </div>
          <Afishauz width={114} className='text-afisha' />
        </section>
      </DialogContent>
    </Dialog>
  );
}
